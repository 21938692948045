<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`平台优势`"
               :en_title="`Industry Background & Business Pain Points`"></Paragraph>
    <Contentlist :datalist="datalist1"></Contentlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`平台架构`"
               :en_title="`Platform Architecture`"></Paragraph>
    <!-- <div style="margin-left: 10%;width: 80%;display: flex;flex-direction: row">
      <div style="width: 55%;border:1px solid #d3dce6">
        <img src="@/assets/image/chain/baas/ant/autimg.png" style="width: 100%">
      </div>
      <div style="width: 100%">
        <Stitlestrlist :datalist="datalist" style=""></Stitlestrlist>
      </div>
    </div> -->
    <Contentlist :datalist="datalist" style=""></Contentlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`平台能力`"
               :en_title="`Platform Capabilities`"></Paragraph>
    <Contentlist :datalist="datalist2"></Contentlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`平台特点`"
               :en_title="`Platform features`"></Paragraph>
    <Contentlist :datalist="datalist3" style="margin-bottom: 6rem"></Contentlist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Contentlist from '@/components/chain/basic/common/contentlist';
// import Stitlestrlist from '@/components/chain/basic/common/stitlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Contentlist,
    // Stitlestrlist,
  },
  data() {
    return {
      datalist: [
        { title: '1）“1+X+Y”平台化架构', list: ['蚂蚁链 BaaS 2.0 基于“1+X+Y”的先进理念进行全新定义和顶层设计。'] },
        { title: '2）“1”指区块链核心平台', list: ['提供包括TEE芯片级的强隐私保护能力，满足政府、金融等场景的链上隐私数据强隐私防盗用要求。'] },
        { title: '3）“Y”指统一跨链接入平台', list: ['供超大规模组网能力，最大可支持1000条链+的跨链组网。'] },
        { title: '4）“X”指区块链应用生态', list: ['蚂蚁链BaaS2.0为区块链DApp的构建提供丰富的标准组件支持，支持零代码一键存证以及极速智能合约的开发。'] },
      ],
      datalist1: [{
        title: '1）自主可控的高性能平台',
        list: [
          '平台依托蚂蚁集团支付宝的海量并发技术经验，交易支持秒级确认。提供海量数据存储，具备万级 TPS 的处理能力。',
        ],
      }, {
        title: '2）丰富的落地场景',
        list: [
          '蚂蚁链目前已落地超过 50 个区块链应用场景，全方位覆盖金融、政务服务、银行、医疗、生活、零售、通用服务等众多行业。',
        ],
      }, {
        title: '3）灵活支持多种底层框架',
        list: [
          '在支持自研引擎的同时也支持 Fabric、Quorum 等开源框架，同时也相应提供完善的 PaaS 层服务能力，满足不同企业对底层框架的选型要求。',
        ],
      }, {
        title: '4）领先的技术实力',
        list: [
          '蚂蚁链团队在共识性能、隐私保护，节点全球部署等关键技术研发上均有突破。连续四年位居全球区块链专利申请数第一。',
        ],
      },
      ],
      datalist2: [{
        title: '1）“双10亿+”性能',
        list: [
          '支持10亿+的链上账户数，以及链上各类交易10亿/天的处理能力，支撑亿级城市的区块链的平台的建设。',
        ],
      }, {
        title: '2）10万+跨链信息处理能力',
        list: [
          '跨链中继组件性能具备横向扩展能力，能同时处理十万级别的跨链流量，且实现端到端延时毫秒级。',
        ],
      }, {
        title: '3）25000+TPS存储能力',
        list: [
          '在极其严格的全局共识和单片条件下，存证性能达到每链 25,000 TPS。',
        ],
      },
      ],
      datalist3: [{
        title: '1）高性能、高可靠',
        list: [
          '蚂蚁集团自主研发的联盟区块链技术和金融级区块链 BaaS 平台，目前拥有数百人的核心技术团队，并由阿里巴巴达摩院金融科技实验室提供技术支持。BaaS 平台不仅提供高速并行共识能力，实现秒级交易确认，且具备高可靠和高容错性。',
        ],
      }, {
        title: '2）10万+跨链信息处理能力',
        list: [
          '不仅从区块链底层实现了金融级隐私保护能力，在 BaaS 服务层也提供了对于企业身份、实人身份、内容安全等认证的能力。同时，蚂蚁 BaaS 平台充分利用了阿里云的云平台安全能力（如 专有网络 VPC ）来保证区块链网络的安全。',
        ],
      }, {
        title: '3）25000+TPS存储能力',
        list: [
          '快速的区块链部署能力、丰富的业务合约模版极大地减少了客户的区块链投入成本，降低了使用门槛，使客户不需要了解区块链很深的细节，也能利用该技术的能力赋能业务。',
        ],
      }, {
        title: '4）25000+TPS存储能力',
        list: [
          '区块链有一个相对来说深入人心的特点——去中心化。蚂蚁链 BaaS 在跨网络、跨不同云的平台上，以及跨公有云和用户 IT 环境的场景下，提供了相应的支撑和自动化的部署能力。',
        ],
      },
      ],
      title: '蚂蚁BaaS服务',
      body:
          '自主研发的具备高性能、强隐私保护的区块链技术平台，致力于打造一站式应用开发平台及服务，'
          + '帮助客户及合作伙伴快速轻松的搭建各类业务场景下的区块链应用，助力实体经济发展。',
      image: require('@/assets/image/chain/baas/ant/ant.jpg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/baas/ant/3_01.jpg'),
        require('@/assets/image/chain/baas/ant/3_02.jpg'),
        require('@/assets/image/chain/baas/ant/3_03.jpg'),
        require('@/assets/image/chain/baas/ant/3_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
